import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'

import './channels'
import './controllers'

// Javascript imports
import './javascripts/admin/turbolinks_prepare_for_cache'
// import './javascripts/shared/youtube'
import './javascripts/shared/map_init'

import ApexCharts from 'apexcharts'

window.ApexCharts = ApexCharts
ActiveStorage.start()

// Platform requires
require('trix')
require('@rails/actiontext')
document.addEventListener('turbo:load', (event) => {})
